import React, { useState, useEffect } from 'react'
import PageWrapper from '../../containers/app/PageWrapper'
import PrimaryButton from '../../components/buttons/PrimaryButton'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router'
import UserDetailsList from '../../containers/users/UserDetailsList'
import useApi from '../../store/api/apiContext'
import { ChevronRightIcon } from '@heroicons/react/solid'
import SecondaryButton from '../../components/buttons/SecondaryButton'
import Loader from '../../components/loaders/Loader'
import UserCandidates from '../../containers/users/UserCandidates'
import AccessWrapper from '../../containers/app/AccessWrapper';
import useUserRoleVerifier from '../../hooks/useUserRoleVerifier'
import TitleSection from '../../components/app/TitleSection'
import DropdownMenuButton from '../../components/menus/DropdownMenuButton'
import WarningModal from '../../components/modal/WarningModal'
import useAppNotification from '../../store/appNotification/appNotificationContext'

function UserDetails({ access }) {
  const navigate = useNavigate();
  const { id } = useParams();
  
  const [ user, setUser ] = useState([]);
  const [ userCandidates, setUserCandidates ] = useState([])
  const [ isLoadingUser, setIsLoadingUser ] = useState(true);
  const [ isLoadingCandidate, setIsLoadingCandidate ] = useState(true);
  const { role, verifyBoolAccess, verifyUserIsWithinScope, verifyArrayAccess } = useUserRoleVerifier();

  const [ showWarningModal, setShowWarningModal ] = useState(false)
  const { showSuccessNotification } = useAppNotification()
  const shouldViewUserCandidates = verifyBoolAccess(role.g_candidates.g_4_manage_candidates);

  const [ showEdit, setShowEdit ] = useState(false);
  const [ showDelete, setShowDelete ] = useState(false)

  const { fetchUser, fetchUserCandidates, deleteUser } = useApi();

  const fetchUserFromApi = (source, id) => {
    fetchUser(source, id).then(res => {
      setUser(res.data);
      setIsLoadingUser(false)
    })
    .catch(err => {
      navigate('/')
      throw err
    })
  }

  const items = [
    [
      {
        text: 'Edit user',
        linkTo: `/users/edit/${id}`,
        show: showEdit
      },
      {
        text: 'Delete user',
        onClick: () => setShowWarningModal(true),
        show: showDelete
      }
    ]
  ]

  const fetchUserCandidatesFromApi = (source, id) => {
    fetchUserCandidates(source, id).then(res => {
      setUserCandidates(res.data);
      setIsLoadingCandidate(false)
    })
  }

  const confirmDelete = async () => {
    setShowWarningModal(false)
    deleteUser(id).then(res => {
      showSuccessNotification('User was correctly deleted')
      navigate('/users')
    })
  }

  useEffect(() => {
    if (
      verifyBoolAccess(role.f_users.f_2_4_edit_users) &&
      verifyUserIsWithinScope(user?.user_group_id, user?.role_id)
    ) setShowEdit(true)

    if (
      verifyBoolAccess(role.f_users.f_2_5_delete_users) &&
      verifyUserIsWithinScope(user?.user_group_id, user?.role_id)
    ) setShowDelete(true)
    
  })

  useEffect(() => {
    let source = axios.CancelToken.source();
    fetchUserFromApi(source, id);
    if (shouldViewUserCandidates) fetchUserCandidatesFromApi(source, id);
    else setIsLoadingCandidate(false)
    return () => {
      source.cancel();
    };
  }, [id])
  
  const renderTopSection = () => {
    return (
      <>
        <DropdownMenuButton buttonText="More actions" items={items} access={showEdit || showDelete}/>
        <PrimaryButton text='Back' onClick={() => navigate(-1) }/>
        <WarningModal title="Delete user?" showWarning={showWarningModal} message="Proceeding will remove user from all job orders assigned, and the user will no longer be able to log in to CATS." buttonText={'Confirm delete'} onClick1={() => confirmDelete()} onClick2={() => setShowWarningModal(false)}/>
      </>
    )
  }

  const renderTitle = () => {
    let firstNames = user?.first_names || ''
    let lastNames = user?.last_names || ''
    return (
      <TitleSection title='Users' tableUrl='/users' resource={firstNames + ' ' + lastNames}/>
    )
  }
  
  return (
    <PageWrapper
      title={renderTitle()}
      tabTitle="User details"
      topSection={renderTopSection()}
      access={access}
    >
      <Loader isLoading={isLoadingUser || isLoadingCandidate}>
        <div
          className={`grid ${
            shouldViewUserCandidates ? "grid-cols-6 gap-x-4 gap-y-4" : "grid-cols-4"
          }`}
        >
          <div
            className={`${
              shouldViewUserCandidates
                ? "col-span-6 md:col-span-4"
                : "col-span-4 md:col-span-4"
            }`}
          >
            <UserDetailsList isLoading={isLoadingUser} user={user} />
          </div>
          <AccessWrapper access={shouldViewUserCandidates}>
            <div className="col-span-6 md:col-span-2">
              <UserCandidates candidates={userCandidates} />
            </div>
          </AccessWrapper>
        </div>
      </Loader>
    </PageWrapper>
  );
}

export default UserDetails
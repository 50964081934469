import React, { useState, useEffect, useMemo } from 'react'
import PageWrapper from '../../containers/app/PageWrapper'
import PrimaryButton from '../../components/buttons/PrimaryButton'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router'
import CandidateDetailsList from '../../containers/candidates/CandidateDetailsList'
import useApi from '../../store/api/apiContext'
import Loader from '../../components/loaders/Loader'
import useUserRoleVerifier from '../../hooks/useUserRoleVerifier';
import Comments from '../../containers/comments/Comments';
import AccessWrapper from '../../containers/app/AccessWrapper';
import Attachments from '../../containers/attachments/Attachments';
import CandidateApplications from '../../containers/candidates/CandidateApplications'
import TitleSection from '../../components/app/TitleSection'
import DropdownMenuButton from '../../components/menus/DropdownMenuButton'
import WarningModal from '../../components/modal/WarningModal'
import useAppNotification from '../../store/appNotification/appNotificationContext'
import { ExclamationIcon } from '@heroicons/react/outline'

function CandidateDetails({ access }) {
  const navigate = useNavigate();
  const { id } = useParams();
  
  const { verifyBoolAccess, verifyUserId, role, verifyArrayAccess } = useUserRoleVerifier();

  const shouldViewCandidateApplications = verifyBoolAccess(role.h_job_orders.h_1_view_job_orders);
  
  const [ candidate, setCandidate ] = useState([]);
  const [ isLoadingCandidate, setIsLoadingCandidate ] = useState(true);
  const [ showWarningModal, setShowWarningModal ] = useState(false)

  const { showSuccessNotification } = useAppNotification()
  
  const [ candidateApplications, setCandidateApplications ] = useState([]);
  const [ isLoadingCandidateApplications, setIsLoadingCandidateApplications ] = useState(true);
  
  const [ showEdit, setShowEdit ] = useState(false);
  const [ showDelete, setShowDelete ] = useState(false)
  const [ showBlacklistWarning, setShowBlacklistWarning ] = useState(false)
  const { fetchCandidate, fetchCandidateApplications, deleteCandidate, editCandidate } = useApi();
  
  const showBlacklist = useMemo(() => {
    const canBlacklistAllCandidates = verifyBoolAccess(role.g_candidates.g_4_3_edit_candidates) && verifyArrayAccess(role.g_candidates.g_4_3_1_editable_properties, 'blacklisted');
    const canBlacklistHisCandidate = verifyUserId(candidate?.original_owner) && verifyBoolAccess(role.g_candidates.g_2_edit_my_candidate) && verifyArrayAccess(role.g_candidates.g_2_1_editable_properties_my_candidate, 'blacklisted');
    let _showBlacklist = canBlacklistAllCandidates || canBlacklistHisCandidate
    return _showBlacklist
  }, [candidate])
  
  const items = [
    [
      {
        text: 'Edit candidate',
        linkTo: `/candidates/edit/${id}`,
        show: showEdit
      },
      {
        text: 'Delete candidate',
        onClick: () => setShowWarningModal(true),
        show: showDelete
      },
      {
        text: candidate?.blacklisted ? 'Remove blacklisted flag' : 'Flag as blacklisted',
        onClick: () => candidate?.blacklisted ? handleBlacklistClick() : setShowBlacklistWarning(true),
        show: showBlacklist
      }
    ]
  ]


  const handleBlacklistClick = async () => {
    await editCandidate(id, {
      blacklisted: !candidate?.blacklisted
    }).then(res => {
      showSuccessNotification(res.data.blacklisted ? 'Candidate has been flagged as blacklisted.' : 'Removed blacklisted flag')
      setShowBlacklistWarning(false)
      setCandidate(prev => ({
        ...prev, 
        blacklisted: res.data.blacklisted
      }))
    })
  }

  const confirmDelete = async () => {
    setShowWarningModal(false)
    deleteCandidate(id).then(res => {
      showSuccessNotification('Candidate was correctly deleted')
      navigate('/candidates')
    })
  }

  const fetchCandidateFromApi = async (id, source) => {
    fetchCandidate(id, source).then(res => {
      setCandidate(res.data);
      setIsLoadingCandidate(false);
    })
    .catch(err => {
      navigate('/')
      throw err;
    })
  }

  const fetchCandidateApplicationsFromApi = async (id, source) => {
    fetchCandidateApplications(id, source).then(res => {
      setCandidateApplications(res.data);
      setIsLoadingCandidateApplications(false);
    })
  }

  useEffect(() => {
    verifyBoolAccess(role.g_candidates.g_4_3_edit_candidates) || (verifyUserId(candidate?.original_owner) && verifyBoolAccess(role.g_candidates.g_2_edit_my_candidate)) ? setShowEdit(true) : setShowEdit(false);
    verifyBoolAccess(role.g_candidates.g_3_delete_my_candidate && verifyUserId(candidate?.original_owner)) || verifyBoolAccess(role.g_candidates.g_4_4_delete_candidates) ? setShowDelete(true) : setShowDelete(false);

  }, [candidate])

  useEffect(() => {
    if (!id) return;
    let source = axios.CancelToken.source();
    fetchCandidateFromApi(id, source);
    return () => {
      source.cancel();
    };
  }, [id])

  useEffect(() => {
    if (!candidate?.id) return;
    let source = axios.CancelToken.source();
    if (shouldViewCandidateApplications) fetchCandidateApplicationsFromApi(candidate?.id, source);
    else setIsLoadingCandidateApplications(false);
    return () => {
      source.cancel();
    };
  }, [candidate, shouldViewCandidateApplications]);
  
  const renderTopSection = () => {
    return (
      <>
        <DropdownMenuButton buttonText="More actions" items={items} access={showEdit || showDelete}/>
        <PrimaryButton text='Back' onClick={() => navigate(-1) }/>
        <WarningModal title="Delete candidate?" showWarning={showWarningModal} message="Deleting this candidate will irreversibly delete all his applications. Should only be done for candidates that have not been submitted in any job order." buttonText={'Confirm delete'} onClick1={() => confirmDelete()} onClick2={() => setShowWarningModal(false)}/>
        <WarningModal title={"Flag as blacklisted"} showWarning={showBlacklistWarning} message={"By clicking Confirm you will flag this candidate for other users. We recommend you write a comment providing more information."} buttonText="Confirm" onClick1={() => handleBlacklistClick()} onClick2={() => setShowBlacklistWarning(false)} />
      </>
    )
  }

   const renderTitle = () => {
    let firstNames = candidate.first_names || ''
    let lastNames = candidate.last_names || ''
    return (
      <TitleSection title='Candidates' tableUrl='/candidates' resource={firstNames + ' ' + lastNames} />
    )
  }
  return (
    <PageWrapper title={renderTitle()} tabTitle='Candidate details' topSection={renderTopSection()} access={access}>
      <Loader isLoading={isLoadingCandidate || isLoadingCandidateApplications}>
        <div className='grid grid-cols-3 gap-x-4 gap-y-4'>
        {candidate?.blacklisted && <div className='col-span-3 bg-red-100 rounded-lg flex space-x-1 text-red-700 items-center text-md p-4'>
          <ExclamationIcon className=' h-6 w-6 mr-2 text-red-500' />
          <p>This candidate has been blacklisted</p>
        </div>}
          <div className='col-span-3 lg:col-span-2 flex flex-col space-y-4  overflow-y-auto'>  
            <CandidateDetailsList candidate={candidate}/>
          </div>
          <div className='col-span-3 lg:col-span-1 flex flex-col space-y-4 overflow-y-auto'>
            <AccessWrapper access={shouldViewCandidateApplications}>
              <CandidateApplications applications={candidateApplications}/>
            </AccessWrapper>
            <AccessWrapper access={role.j_attachments.j_2_view_candidate_attachments}>
              <Attachments id={id} type={'candidates'}/>
            </AccessWrapper>
            <AccessWrapper access={role.i_comments.i_2_view_candidate_comments}>
              <Comments />
            </AccessWrapper>
          </div>
        </div>
      </Loader>
    </PageWrapper>
  )
}

export default CandidateDetails
import React, { useState, useEffect } from 'react'
import PageWrapper from '../../containers/app/PageWrapper'
import PrimaryButton from '../../components/buttons/PrimaryButton'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router'
import role from '../../helpers/enum/role'
import UserGroupDetailsList from '../../containers/userGroups/UserGroupDetailsList'
import useApi from '../../store/api/apiContext'
import { ChevronRightIcon } from '@heroicons/react/solid'
import SecondaryButton from '../../components/buttons/SecondaryButton'
import Loader from '../../components/loaders/Loader'
import DropdownMenuButton from '../../components/menus/DropdownMenuButton'
import WarningModal from '../../components/modal/WarningModal'
import useAppNotification from '../../store/appNotification/appNotificationContext'
import TitleSection from '../../components/app/TitleSection'

function UserGroupDetails({ access }) {
  const navigate = useNavigate();
  const { id } = useParams();
  
  const [ userGroup, setUserGroup ] = useState([]);
  const [ isLoading, setIsLoading ] = useState(true);
  const [ showWarningModal, setShowWarningModal ] = useState(false)

  const { showSuccessNotification } = useAppNotification()

  const { fetchUserGroup, deleteUserGroup } = useApi();

  const items = [
    [
      {
        text: 'Edit user group',
        linkTo: `/user-groups/edit/${id}`,
        show: true
      },
      {
        text: 'Delete user group',
        onClick: () => setShowWarningModal(true),
        show: true
      }
    ]
  ]

  const fetchUserGroupFromApi = async (source, id) => {
    fetchUserGroup(source, id).then(res => {
      setUserGroup(res.data);
      setIsLoading(false);
    })
    .catch(err => {
      navigate('/')
      throw err
    })
  }

  const confirmDelete = async () => {
    setShowWarningModal(false)
    deleteUserGroup(id).then(res => {
      showSuccessNotification('User group was correctly deleted')
      navigate('/user-groups')
    })
  }


  useEffect(() => {
    let source = axios.CancelToken.source();
    fetchUserGroupFromApi(source, id);
    return () => {
      source.cancel();
    };
  }, [id])

  
  
  const renderTopSection = () => {
    return (
      <>
        <DropdownMenuButton buttonText="More actions" items={items} access={role.b_manage_user_groups}/>
        <PrimaryButton text='Back' onClick={() => navigate(-1) }/>
        <WarningModal title="Delete user group?" showWarning={showWarningModal} message="Unable to delete user groups if users are still associated. Please make sure to remove all users before confirming." buttonText={'Confirm delete'} onClick1={() => confirmDelete()} onClick2={() => setShowWarningModal(false)}/>
      </>
    )
  }

  const renderTitle = () => {
    let userGroupName = userGroup.name || ''
    return (
      <TitleSection title='User groups' tableUrl='/user-groups' resource={userGroupName}/>
    )
  }
  
  return (
    <PageWrapper title={renderTitle()} tabTitle='User group details' topSection={renderTopSection()} access={access}>
      <div className='space-y-4'>
        <Loader isLoading={isLoading}>
          <UserGroupDetailsList userGroup={userGroup}/>
        </Loader>
      </div>
    </PageWrapper>
  )
}

export default UserGroupDetails
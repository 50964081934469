import React, { useState, useEffect } from 'react'
import PageWrapper from '../../containers/app/PageWrapper'
import PrimaryButton from '../../components/buttons/PrimaryButton'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router'
import FrameworkContractDetailsList from '../../containers/frameworkContracts/FrameworkContractDetailsList'
import useApi from '../../store/api/apiContext'
import Loader from '../../components/loaders/Loader'
import TitleSection from '../../components/app/TitleSection'
import DropdownMenuButton from '../../components/menus/DropdownMenuButton'
import WarningModal from '../../components/modal/WarningModal'
import useAppNotification from '../../store/appNotification/appNotificationContext'
import useUserRoleVerifier from '../../hooks/useUserRoleVerifier'
import FrameworkContractJobOrders from '../../containers/frameworkContracts/FrameworkContractJobOrders'
import AccessWrapper from '../../containers/app/AccessWrapper'

function FrameworkContractDetails({ access }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const {verifyBoolAccess, verifyArrayAccess, role, userRole} = useUserRoleVerifier();
  const [ frameworkContract, setFrameworkContract ] = useState([]);
  const [ jobOrders, setJobOrders ] = useState([]);
  const [ isLoading, setIsLoading ] = useState(true);
  const [ showWarningModal, setShowWarningModal ] = useState(false)
  const [ showJobOrders, setShowJobOrders ] = useState(false) 
  const [ linkToClients, setLinkToClients ] = useState(false)
  const { showSuccessNotification } = useAppNotification()

  const { fetchFrameworkContract, deleteFrameworkContract, getFrameworkContractJobOrders } = useApi();

  const fetchFrameworkContractFromApi = async (id, source) => {
    fetchFrameworkContract(id, source).then(res => {
      setFrameworkContract(res.data);
      setIsLoading(false);
    })
    .catch(err => {
      navigate('/')
      throw err
    })
    
  }

  const items = [
    [
      {
        text: 'Edit contract',
        linkTo: `/fw-contracts/edit/${id}`,
        show: verifyBoolAccess(role.d_manage_framework_contracts)
      },
      {
        text: 'Delete contract',
        onClick: () => setShowWarningModal(true),
        show: verifyBoolAccess(role.d_manage_framework_contracts)
      }
    ]
  ]

  const confirmDelete = async () => {
    setShowWarningModal(false)
    deleteFrameworkContract(id).then(res => {
      showSuccessNotification('Framework contract was correctly deleted')
      navigate('/fw-contracts')
    })
  }


  useEffect(() => {
    if (!Object.keys(userRole).length || !id) return
    let _showJobOrders = verifyArrayAccess(role.h_job_orders.h_1_2_allowed_fwc, id) && verifyBoolAccess(role.h_job_orders.h_1_view_job_orders)
    setLinkToClients(verifyBoolAccess(role.e_manage_clients))
    setShowJobOrders(_showJobOrders)
    if (_showJobOrders) {
      getFrameworkContractJobOrders(id).then(res => {
        setJobOrders(res.data)
      })
    }
  },[userRole, id])

  useEffect(() => {
    let source = axios.CancelToken.source();
    fetchFrameworkContractFromApi(id, source);
    return () => {
      source.cancel();
    };
  }, [id])  
  
  const renderTopSection = () => {
    return (
      <>
        <DropdownMenuButton buttonText="More actions" items={items} access={role.d_manage_framework_contracts}/>
        <PrimaryButton text='Back' onClick={() => navigate(-1) }/>
        <WarningModal title="Delete user group?" showWarning={showWarningModal} message="Unable to delete framework contracts if still associated with job orders. Please make sure to remove contract from all job orders before confirming." buttonText={'Confirm delete'} onClick1={() => confirmDelete()} onClick2={() => setShowWarningModal(false)}/>
      </>
    )
  }

  const renderTitle = () => {
    let frameworkContractRef = frameworkContract.contract_ref || ''
    return <TitleSection title='Framework contracts' tableUrl='/fw-contracts' resource={frameworkContractRef}/>
  }
  
  return (
    <PageWrapper title={renderTitle()} tabTitle='FWC details' topSection={renderTopSection()} access={access}>
      <div className='space-y-4'>
        <Loader isLoading={isLoading}>
          <div className='grid grid-cols-3 gap-x-4 gap-y-4'>
            <div className='col-span-3 lg:col-span-2 flex flex-col space-y-4 overflow-y-auto'>  
              <FrameworkContractDetailsList frameworkContract={frameworkContract}/>
            </div>
            <AccessWrapper access={showJobOrders}>
              <div className="col-span-3 lg:col-span-1 h-full overflow-y-auto">
                <FrameworkContractJobOrders jobOrders={jobOrders} linkToClients={linkToClients}/>
              </div>
            </AccessWrapper>
          </div>
        </Loader>
      </div>
    </PageWrapper>
  )
}

export default FrameworkContractDetails
import React, { useState, useEffect } from 'react'
import Modal from "../../../components/modal/Modal";
import useApi from '../../../store/api/apiContext';
import ReactSelect from '../../../components/inputs/ReactSelect';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import NumberInput from '../../../components/inputs/NumberInput'
import TextArea from '../../../components/inputs/TextArea'
import useUserRoleVerifier from '../../../hooks/useUserRoleVerifier';
import { applicationStatusIDs } from '../../../helpers/enum/applicationStatuses';
import useAppNotification from '../../../store/appNotification/appNotificationContext';
import DescriptionItem from '../../../components/descriptionLists/DescriptionItem';
import useJobOrders from '../../../store/jobOrders/jobOrdersContext';
import { SocketContext } from '../../../store/socket/socketProvider';
import WarningText from '../../../components/warnings/WarningText';
import { ExclamationIcon } from '@heroicons/react/outline';

function AddApplicationModal({ show, hide, applications, jobOrderId }) {
  
  const { showAppNotification, appNotificationTypes } = useAppNotification();
  const { fetchCandidates, fetchMyCandidates } = useApi();
  const { addApplicationLog } = useJobOrders();
  const { role, verifyBoolAccess, currentUser } = useUserRoleVerifier();
  
  const [candidates, setCandidates ] = useState([])
  
  const [selectedCandidate, setSelectedCandidate ] = useState(null)
  const [cost, setCost ] = useState(null)
  //TODO: Error message when using addApplicationMOdal : Warning: `value` prop on `textarea` should not be null. Consider using an empty string to clear the component or `undefined` for uncontrolled components.
  const [description, setDescription ] = useState(null)

  const socket = React.useContext(SocketContext);

  useEffect(() => {
    if (!show) return;
    //if has assign applicants, fetch all candidates, else just my candidates
    let fetchRequest;
    if (verifyBoolAccess(role.h_job_orders.h_3_3_assign_applicants)) {
      fetchRequest = () => fetchCandidates('applications');
    } else {
      fetchRequest = fetchMyCandidates;
    }
    fetchRequest().then(res => {
      let unassignedCandidates = res.data.filter(e => {
        return !applications.some(a => a.applicant_id === e.id)
      })
      let options = unassignedCandidates.map(e => ({ 
        label: e.first_names + ' ' + e.last_names,
        value: e.id, 
        owner_first_names: e.originalOwner.first_names,
        owner_last_names: e.originalOwner.last_names, 
        first_names: e.first_names, 
        last_names: e.last_names,
        blacklisted: e?.blacklisted
      }));
      setCandidates(options);
    })

    return () => {
      setCandidates([])
      setSelectedCandidate(null);
    }
  }, [show])

  const handleAddCandidate = async () => {
    let candidateApplication = {
      applicant_id: selectedCandidate.value,
      status: applicationStatusIDs.proposed,
      description: description,
      cost: cost,
      created_by: currentUser.id,
    };
    addApplicationLog(jobOrderId, candidateApplication).then(res => {
      showAppNotification(appNotificationTypes.SUCCESS, 'Success', 'Added candidates to job order');
      let newApplication = {
        ...res.data,
        candidate: {
          first_names: selectedCandidate.first_names,
          last_names: selectedCandidate.last_names,
          is_within_scope: true,
          originalOwner: {
            first_names: selectedCandidate.owner_first_names,
            last_names: selectedCandidate.owner_last_names,
            is_within_scope: true
          }
        },
        user: {
          first_names: currentUser.first_names,
          id: currentUser.id
        },
      }
      socket.emit("add-application", {
        jobOrderId,
        newApplication,
        currentUserId: currentUser.id,
      });
      setSelectedCandidate(null);
      setCost(null);
      setDescription(null);
      hide(false);
    });
  }


  return (
    <Modal show={show} hide={hide} title={'Add candidate application'}>
      <div className='h-full flex flex-col justify-between space-y-2'>
        <div className='flex flex-col space-y-2'>
          <ReactSelect 
            label='Select candidate to add to this job order' 
            options={candidates} 
            onChange={(e) => setSelectedCandidate(e)}
            selectedOption={selectedCandidate}
          />
        {selectedCandidate?.blacklisted && <WarningText text='This candidate has been blacklisted'/>}
        </div>
        {
            selectedCandidate && 
            <>
              <div>
                <DescriptionItem 
                  data={selectedCandidate.owner_first_names + ' ' + selectedCandidate.owner_last_names}
                  label={'Belongs to'}
                />
              </div>
              <div>
                <NumberInput 
                  label='Cost (€/day)'
                  onChange={(e) => setCost(e.target.value)}
                  value={cost}
                />
              </div>
              <div>
                <TextArea
                  label='Description'
                  onChange={(e) => setDescription(e.target.value)}
                  value={description}
                  rows={3}
                />
              </div>
            </>
        }
        <div className='w-full flex flex-row-reverse mt-4'>
          <PrimaryButton show={true} disabled={!selectedCandidate ? true : false} text={'Add candidate'} onClick={handleAddCandidate}/>
        </div>
      </div>
    </Modal>
  )
}

export default AddApplicationModal
import React, { useState, useEffect } from 'react'
import PageWrapper from '../../containers/app/PageWrapper'
import PrimaryButton from '../../components/buttons/PrimaryButton'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router'
import ClientDetailsList from '../../containers/clients/ClientDetailsList'
import useApi from '../../store/api/apiContext'
import Loader from '../../components/loaders/Loader'
import TitleSection from '../../components/app/TitleSection'
import WarningModal from '../../components/modal/WarningModal'
import useAppNotification from '../../store/appNotification/appNotificationContext'
import DropdownMenuButton from '../../components/menus/DropdownMenuButton'
import useUserRoleVerifier from '../../hooks/useUserRoleVerifier'
import ClientJobOrders from '../../containers/clients/ClientJobOrders'
import AccessWrapper from '../../containers/app/AccessWrapper'
import Comments from '../../containers/comments/Comments'

function ClientDetails({ access }) {
  const navigate = useNavigate();
  const { id } = useParams();
  
  const [ client, setClient ] = useState([]);
  const [ isLoading, setIsLoading ] = useState(true);
  const [ showWarningModal, setShowWarningModal ] = useState(false)
  const [ jobOrders, setJobOrders ] = useState([])

  const { showSuccessNotification } = useAppNotification()
  const {verifyBoolAccess, userRole, role} = useUserRoleVerifier();

  const { fetchClient, deleteClient, getClientJobOrders } = useApi();
  const [ showJobOrders, setShowJobOrders ] = useState(false);
  const [ linkToFwC, setLinkToFwC ] = useState(false)

  const fetchClientFromApi = async (id, source) => {
    fetchClient(id, source).then(res => {
      setClient(res.data);
      setIsLoading(false);
    })
    .catch(err => {
      navigate('/')
      throw err
    })
  }

  useEffect(() => {
    if (!Object.keys(userRole).length || !id) return
    let _showJobOrders = verifyBoolAccess(role.h_job_orders.h_1_view_job_orders)
    setLinkToFwC(verifyBoolAccess(role.d_manage_framework_contracts))
    setShowJobOrders(_showJobOrders)
    if (_showJobOrders) {
      getClientJobOrders(id).then(res => {
        setJobOrders(res.data)
      })
    }
  },[userRole, id])

  const items = [
    [
      {
        text: 'Edit client',
        linkTo: `/clients/edit/${id}`,
        show: true
      },
      {
        text: 'Delete client',
        onClick: () => setShowWarningModal(true),
        show: true
      }
    ]
  ]

  const confirmDelete = async () => {
    setShowWarningModal(false)
    deleteClient(id).then(res => {
      showSuccessNotification('Client was correctly deleted')
      navigate('/clients')
    })
  }

  useEffect(() => {
    let source = axios.CancelToken.source();
    fetchClientFromApi(id, source);
    return () => {
      source.cancel();
    };
  }, [id])

  
  
  const renderTopSection = () => {
    return (
      <>
        <DropdownMenuButton buttonText="More actions" items={items} access={role.b_manage_user_groups}/>
        <PrimaryButton text='Back' onClick={() => navigate(-1) }/>
        <WarningModal title="Delete client?" showWarning={showWarningModal} message="Unable to delete clients if job orders are still associated. Please make sure to remove client from all job orders before confirming." buttonText={'Confirm delete'} onClick1={() => confirmDelete()} onClick2={() => setShowWarningModal(false)}/>
      </>
    )
  }

  const renderTitle = () => {
    let clientName = client.name || ''
    return (
      <TitleSection title='Clients' tableUrl='/clients' resource={clientName}/>
    )
  }
  
  return (
    <PageWrapper title={renderTitle()} tabTitle='Client details' topSection={renderTopSection()} access={access}>
        <Loader isLoading={isLoading}>
          <div className='grid grid-cols-3 gap-x-4 gap-y-4'>
            <div className='col-span-3 lg:col-span-2 flex flex-col space-y-4  overflow-y-auto'>  
              <ClientDetailsList client={client} />
            </div>
            <div className='col-span-3 lg:col-span-1 flex flex-col space-y-4  overflow-y-auto'>
              <AccessWrapper access={role.h_job_orders.h_1_view_job_orders}>
                <ClientJobOrders jobOrders={jobOrders} linkToFwC={linkToFwC}/>
              </AccessWrapper>
              <AccessWrapper access={role.i_comments.i_3_view_client_comments}>
                <Comments/>
              </AccessWrapper>
            </div>
          </div>
        </Loader>
    </PageWrapper>
  )
}

export default ClientDetails